import { FC } from "react";
import { useMapState } from "../Map/v2/GoogleMap/MapStateContext";
import { IconBox, IconsWrapper, IconTooltip } from "../Map/Map.styled";
import { ShowRideButton } from "./MultipleRidesGoogleMap.styled";
import { ShowRidesIcon } from "assets/icons";
import { useTranslation } from "react-i18next";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";

interface MultipleRidesTopRightSectionProps {
  showRidesModal: boolean;
  onShowRidesButtonClick?: () => void;
}

const MultipleRidesTopRightSection: FC<MultipleRidesTopRightSectionProps> = ({
  showRidesModal,
  onShowRidesButtonClick
}: MultipleRidesTopRightSectionProps) => {
  const { t } = useTranslation();
  const { showSatelliteMode, setShowSatelliteMode } = useMapState();

  return (
    <IconsWrapper>
      <IconTooltip title={t("common.showSatelliteMode")} arrow>
        <IconBox
          id="settelite-view"
          topMargin={false}
          active={showSatelliteMode}
          onClick={() => setShowSatelliteMode(!showSatelliteMode)}
        >
          {!showSatelliteMode ? (
            <SatelliteAltIcon color="secondary" fontSize="small" />
          ) : (
            <SatelliteAltIcon fontSize="small" />
          )}
        </IconBox>
      </IconTooltip>
      {!showRidesModal && (
        <ShowRideButton
          onClick={() => onShowRidesButtonClick && onShowRidesButtonClick()}
        >
          <ShowRidesIcon />
          {t("buttonsText.showRides")}
        </ShowRideButton>
      )}
    </IconsWrapper>
  );
};

export default MultipleRidesTopRightSection;
