import React, { FC, Fragment, useMemo } from "react";

import { PolylineF } from "@react-google-maps/api";
import { objectColors } from "app/data/helpers";
import { GroupedRide } from "./types";

interface PolylineProps {
  groupedRides: GroupedRide[];
  highlightRideId: string | undefined;
  onClick?: (rideUniqueId: string) => void;
}

const MultiRidesPolyline: FC<PolylineProps> = ({
  groupedRides,
  highlightRideId,
  onClick
}: PolylineProps) => {
  const renderPolyline = useMemo(() => {
    return groupedRides.map((groupedRide, index) => {
      const rideUniqueId = groupedRide.object.rideUniqueId;
      const objectIndex = groupedRide.object.objectIndex;
      const rides = groupedRide?.object?.rides ?? [];
      const path = rides.map(
        (ride: { latitude: number; longitude: number }) => ({
          lat: ride.latitude ?? 0,
          lng: ride.longitude ?? 0
        })
      );
      const isHighlighted = rideUniqueId === highlightRideId;

      const options = {
        strokeColor: isHighlighted ? "#845AFC" : objectColors[objectIndex],
        strokeWeight: 10,
        zIndex: isHighlighted ? 2 : 1
      };
      return (
        <Fragment key={index}>
          <PolylineF
            key={index}
            path={path}
            options={options}
            onClick={() => onClick && onClick(rideUniqueId)}
          />
        </Fragment>
      );
    });
  }, [groupedRides, highlightRideId]);

  return <>{groupedRides.length > 0 && <>{renderPolyline}</>}</>;
};

export default React.memo(MultiRidesPolyline);
