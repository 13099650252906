import React, { FC, useState } from "react";

import { MarkerF } from "@react-google-maps/api";
import MultipleRidesMarkerTooltip from "./MultipleRidesMarkerTooltip";
import { ObjectRideLocation } from "./types";

interface MakerProps {
  marker: ObjectRideLocation;
  objectName: string;
  icon: google.maps.Icon;
  isDotMarker: boolean;
  onClick?: (marker: ObjectRideLocation) => void;
}

const MultiRidesMarker: FC<MakerProps> = ({
  marker,
  objectName,
  icon,
  isDotMarker,
  onClick
}: MakerProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <>
      <MarkerF
        position={{
          lat: marker.latitude ?? 0,
          lng: marker.longitude ?? 0
        }}
        onClick={() => onClick && onClick({ ...marker })}
        icon={icon}
        onMouseOver={() => setIsTooltipVisible(true)}
        onMouseOut={() => setIsTooltipVisible(false)}
      />
      {isTooltipVisible && (
        <MultipleRidesMarkerTooltip
          marker={marker}
          objectName={objectName}
          isDotMarker={isDotMarker}
        />
      )}
    </>
  );
};

export default React.memo(MultiRidesMarker);
