import { FC, Fragment, useEffect, useMemo, useState } from "react";
import {
  CloseButton,
  ObjectBorder,
  ObjectColorBox,
  ObjectDateText,
  ObjectItem,
  ObjectList,
  ObjectListContainer,
  ObjectText
} from "./MultipleRidesGoogleMap.styled";
import {
  CrossGreyIcon,
  EndLocation,
  RightArrowIcon,
  StartLocation
} from "assets/icons";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { objectColors } from "app/data/helpers";
import { SearchField, utils } from "@vilocnv/allsetra-core";
import { GroupedRide } from "./types";

interface MultipleRidesObjectsListModalProps {
  groupedRides: GroupedRide[];
  showRidesModal: boolean;
  highlightRideId: string | undefined;
  onItemClick?: (rideUniqueId: string) => void;
  onCloseButtonClick?: () => void;
}

const MultipleRidesObjectsListModal: FC<MultipleRidesObjectsListModalProps> = ({
  groupedRides,
  showRidesModal,
  highlightRideId,
  onItemClick,
  onCloseButtonClick
}: MultipleRidesObjectsListModalProps) => {
  const { t } = useTranslation();
  const [objectNotFound, setObjectNotFound] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredObjects, setFilteredObjects] =
    useState<GroupedRide[]>(groupedRides);

  useEffect(() => {
    if (!searchText) {
      setFilteredObjects(groupedRides);
      setObjectNotFound(false);
    } else {
      const lowercasedSearch = searchText.toLowerCase();
      const filtered = groupedRides.filter((item) =>
        item?.object.objectName?.toLowerCase().includes(lowercasedSearch)
      );
      setFilteredObjects(filtered);
      setObjectNotFound(filtered.length === 0);
    }
  }, [searchText, groupedRides]);

  const renderObjectList = useMemo(() => {
    return filteredObjects.map((groupedRide, index) => {
      const iconUrl = groupedRide.object.objectIcon;
      const objectIndex = groupedRide.object.objectIndex;
      const rideUniqueId = groupedRide.object.rideUniqueId;
      const rides = groupedRide?.object?.rides ?? [];
      const objectName = groupedRide.object.objectName;

      return (
        <ObjectItem
          key={index}
          active={highlightRideId === rideUniqueId}
          onClick={() => onItemClick && onItemClick(rideUniqueId)}
        >
          <ObjectList>
            <Box display={"flex"} alignItems={"center"}>
              <img width={22} src={iconUrl} alt="object-icon" />
              <ObjectText>{objectName}</ObjectText>
            </Box>
            <ObjectColorBox color={objectColors[objectIndex]} />
          </ObjectList>
          <ObjectList>
            <Box display={"flex"} alignItems={"center"}>
              <StartLocation size={18} />
              <ObjectDateText>
                {utils.formatDate({
                  date: rides[0]?.date,
                  whenEmpty: "N/A"
                })}
              </ObjectDateText>
            </Box>
            <RightArrowIcon />
            <Box display={"flex"} alignItems={"center"}>
              <EndLocation size={18} />
              <ObjectDateText>
                {utils.formatDate({
                  date: rides[rides.length - 1]?.date,
                  whenEmpty: "N/A"
                })}
              </ObjectDateText>
            </Box>
          </ObjectList>
        </ObjectItem>
      );
    });
  }, [filteredObjects, highlightRideId]);

  return (
    <>
      {groupedRides.length > 0 && showRidesModal && (
        <Fragment>
          <CloseButton onClick={onCloseButtonClick}>
            <CrossGreyIcon />
          </CloseButton>
          <ObjectListContainer>
            <Box mb={2}>
              <SearchField
                // @ts-ignore
                onChange={(e: any) => setSearchText(e.target.value)}
                value={searchText}
                fullWidth
                placeholder={t("titles.search")}
              />
            </Box>
            {objectNotFound && <Box>{t("typography.notFound")}</Box>}
            {!objectNotFound && <ObjectBorder active />}
            {renderObjectList}
            {!objectNotFound && <ObjectBorder active />}
          </ObjectListContainer>
        </Fragment>
      )}
    </>
  );
};

export default MultipleRidesObjectsListModal;
